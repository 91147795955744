const swiper3 = new Swiper('.members__carousel', {
    wrapperClass: 'members__ribbon',
    slideClass: 'members__person',
    direction: 'horizontal',
    loop: false,
    speed: 1000,
    navigation: {
      prevEl: '.members__control--left',
      nextEl: '.members__control--right',
      disabledClass: 'controls__control--disactive'
  },
    breakpoints: {
      0: {
        slidesPerView: 2,
        spaceBetween: 27,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 27,
      },
    },
});